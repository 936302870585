import * as React from "react";
import Layout from "../../layouts/he";
import ContactForm from "../../components/pages/ContactForm";
import PageHead from "../../components/shared/PageHead";
import content from "../../data/content/contact/contact-he";
import * as styles from "../../styles/pages/contact/index.module.css";

const Contact = (props) => {
  const { sCfSection } = styles;
  return (
    <Layout location={props.location} lang="he">
      <PageHead pageHeadData={content.seo} />
      <article lang="he" className="contact" dir="rtl">
        <section className={sCfSection}>
          <ContactForm content={content} styles={styles} />
        </section>
      </article>
    </Layout>
  );
};

export default Contact;
