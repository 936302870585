module.exports = {
  seo: {
    title: "צור קשר - Jenko",
    description: "",
  },
  title: "צור קשר",
  contactInfo: [
    {
      label: "address",
      value: " הכורמים 28, אשקלון",
    }, 
    {
      label: "email",
      value: "ky@jenko-team.com ",
    },
    {
      label: "mobile",
      value: "050-5771214 972+ ",
      // value: " 050 - 5771214 972+  ",
    },
    // {
    //   label: "phone",
    //   value: "+972  050 - 5771214",
    // },
  ],
  formPlaceHolders: {
    name: "שם מלא",
    email: "אימייל",
    message: "השאר הודעה",
    cta: "שלח",
  },
  tyMessage: ["תודה על פנייתך.", " אחד מנציגנו ייצור עמך קשר בהקדם."],
  errorMessage: ["משהו השתבש וההודעה לא נשלחה!", "אנא נסו שוב..."],
  dir: "ltr",
};
